@import '../../styles/breakpoints.scss';

.whiteText {
  color: var(--white-color);
}

.cancelBtn {
  margin-top: 3rem;

  a {
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.8);

    cursor: pointer;
  }
}

.pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 124px;
  max-width: 1440px;

  &.transparent {
    background-color: transparent;
  }

  @media (max-width: 834px) {
    width: 100%;
    flex-direction: column;
  }
}

.title {
  align-self: stretch;
  text-align: center;
  font-style: normal;
  font-size: 2rem;
  font-weight: 600;

  @media (max-width: 834px) {
    font-size: 1.5rem;
    line-height: normal;
  }

  @media (max-width: 780px) {
    font-size: 32px;
  }
}

.annualContainer {
  display: grid;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  @media all and (min-width: $breakpoint-md) {
    gap: 1rem;
  }
}

.annualToggleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;

  height: 50px;
  border-radius: 12px;
  padding: 4px;
  background: transparent;
  border: 1px solid #e3e1e1;
  gap: 4px;
  position: relative;
  z-index: 0;
  max-width: 36.625rem;

  @media (max-width: 834px) {
    padding: 0px 0px 5px 0px;
    width: 448px;
  }

  @media (max-width: 780px) {
    padding: 0px 4px;
    max-width: 360px;
  }
}

.toggle {
  padding: 0 24px;
  width: 50%;
  border: none;
  height: 100%;
  background-color: transparent;

  @media (max-width: 834px) {
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
  }

  @media (max-width: 780px) {
    width: 50%;
  }
}

.toggleText {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  color: #9c9ea1;
  transition: color 0.1s ease-in-out;

  &.white {
    color: white;
  }

  @media (max-width: 834px) {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.252px;
  }

  @media (max-width: 780px) {
    letter-spacing: -0.252px;
  }
}

.annualTogglebackground {
  position: absolute;
  transition: left 0.3s ease-in-out;
  z-index: -1;
  height: 40px;
  left: 4px;
  width: 50%;
  background: #4e59e3;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &.right {
    left: 50%;
  }

  @media (max-width: 834px) {
    width: 240px;

    &.right {
      left: 205px;
    }
  }

  @media (max-width: 780px) {
    width: 46%;

    &.right {
      left: 185px;
    }
  }
}

.saveMore {
  align-self: stretch;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;

  @media (max-width: 834px) {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.252px;
  }
}

.subtext {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  max-width: 54rem;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 0.8fr);

  &.semrush {
    grid-template-columns: repeat(2, 1fr);
  }

  align-content: center;
  place-items: center;
  gap: 16px;
  margin-bottom: 50px;
  padding: 0 1.5rem;

  opacity: 0;
  transform: translateY(20px);
  transition: 0.8s;

  @media (max-width: 834px) {
    flex: 1;

    gap: 12px;
  }

  @media (max-width: 780px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    max-width: 415px;
  }
}

.onScreen {
  opacity: 1;
  transform: translateY(0px);
}

/** -------------------------- SETTINGS PRICING -------------------------- */

.blackText {
  color: var(--black-color);
}

.modal_new_card {
  margin-top: 10px;
  font-weight: 500;
  cursor: pointer;
}

.setting_pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0;

  .title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
  }

  .annualContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .annualToggleContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #e0e0e0;
      padding: 4px;
      gap: 4px;
      position: relative;
      z-index: 0;

      .background {
        position: absolute;
        transition: 0.3s;
        z-index: -1;
        height: 34px;
        left: 4px;
        width: 90px;
        background: #4e59e3;
        border-radius: 6px;
        &.right {
          left: 98px;
        }
      }

      .saveMore {
        position: absolute;
        margin: 0;
        color: #51cf66;
        right: -165px;
        font-weight: 500;
      }

      .toggle {
        padding: 8px 4px;
        width: 90px;
        border: none;
        background-color: transparent;
        .toggleText {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          text-transform: uppercase;
          &.white {
            color: white;
          }
        }
      }
    }

    .annual {
      text-align: center;
      margin: 0;
      margin-top: 10px;
      font-weight: 500;
      font-size: 18px;
    }
    .subtext {
      text-align: center;
      margin: 0;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 15px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: flex-start;
    place-items: start;
    gap: 30px;
  }

  @media screen and (max-width: '1200px') {
    .cards {
      width: auto;
    }
  }

  @media screen and (max-width: '1024px') {
    height: auto;
    padding: 20px;

    title {
      margin: 25px 0;
    }

    .cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
    }
  }
}

.discountText {
  font-weight: 500;
  line-height: 44px;
  font-size: 28px;
  margin: 0;
  padding: 0;
}
