.gradientBlack {
  background: linear-gradient(90deg, #201f1e -0.34%, #495057 99.66%);
}

.modal_box {
  background-color: #ffff;
  border-radius: 15px;
  padding: 32px;
  outline: none;
}

.modal_button {
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 12px;
  border: none;
  color: #ffff;
  font-family: inherit;
}

.modal_header {
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 40px;

  .modal_title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1;
    font-size: 36px;
    font-weight: 700;
  }

  .modal_close {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
  }
}

.modal_container_column {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}
