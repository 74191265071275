.root > div {
  z-index: 999;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  text-align: center;

  & > div {
    height: fit-content;
    margin-bottom: 0.5rem;
    & > div {
      border-top-color: #fcc419;
    }
  }
}
