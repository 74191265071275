@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';

.cardContainer {
  --card-header-color: var(--purple-color);
  --border-color: #e0e0e0;

  height: 100%;

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    background-color: var(--white-color);

    &.unbordered {
      border: none;
    }

    .cardContent {
      margin: 1.5rem 0;
      display: flex;
      padding: 1.5rem 1rem 6.25rem;
      flex-direction: column;
      align-items: center;
      // gap: 1.5rem;
      flex: 1 0 0;
      border-radius: 1.5rem;
      border: 1px solid var(--ocean-light);

      @media (max-width: 780px) {
        padding-bottom: 1rem;
      }


      &.semrush {
        margin: 0;
      }

      .priceContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .info {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        gap: 1rem;
        text-align: start;
        flex-flow: row nowrap;

        .infoCheck {
          flex-shrink: 0;
          width: 1.25rem;
          height: 1.25rem;
        }

        .gray {
          color: #818181;
        }

        p {
          width: 90%;
        }
      }
    }

    .middleCard {
      margin: 0;
      background-color: #5249d9;
      --info-text-color: var(--white-color);
      --border-color: var(--ocean-light);

      .pricingName {
        margin-top: 1.5rem;

        &.semrush {
          margin: 0;
        }
      }
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 30px;

      .semrushLogo {
        width: 155px;
      }

      a {
        width: 100%;
      }

      .signUpBtn {
        width: 100%;
        display: flex;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 12px;
        border: 3px solid #9baafa;
        background: #fff;

        cursor: pointer;

        color: #495057;
        text-align: center;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
        max-height: 56px;

        &:not(.btnDisabled) {
          @include button;
        }

        &.btnDisabled {
          &:disabled {
            background-color: #e0e0e0;
            color: var(--black-color);
            cursor: not-allowed;
          }
        }
      }

      .yellowButton {
        color: #000;
        background: #ffce4f;
        border: none;
        @include button;
      }

      .semrush {
        font-size: 17px;
      }

      .selectBtn {
        height: 55px;
        width: 100%;
        border-radius: 10px;
        text-transform: uppercase;
        font-family: inherit;
        cursor: pointer;
      }
    }
  }
}

.whiteText {
  color: var(--white-color);
}

.title {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
}

.annualContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;

  .annualToggleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-around;

    .annualToggleText {
      margin: 0 20px;
    }
  }

  .annual {
    text-align: center;
    margin: 10px 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.blackText {
  color: var(--black-color);
}

@media screen and (max-width: 834px) {
  .cardContainer {
    flex: 1;

    .card {
      flex: 1;
      height: 100%;
    }

    .cardContent {
      margin: 0;
      padding: 2rem 1rem 4rem;
      gap: 1.5rem;
    }

    .priceContainer {
      gap: 0;
    }

    // .pricingName {
    //   text-align: center;
    //   font-size: 1.5rem;
    //   line-height: 1.5;
    // }

    .info {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      gap: 1rem;
      text-align: start;
      flex-flow: row nowrap;
    }

    .buttonContainer {
      font-size: 1.5rem;
    }
  }

  .title {
    margin-top: 2.5rem;
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
  }

  .annualContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .annualToggleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-around;
  }

  .annualToggleText {
    margin: 0 20px;
  }

  .annual {
    text-align: center;
    margin: 10px 0;
    font-weight: 500;
    font-size: 14px;
  }
}

@media screen and (max-width: 780px) {
  .info {
    min-height: 0px !important;
  }
}

@media screen and (max-width: 390px) {
  .card_container {
    flex: 1;

    .card {
      .cardContent {
        display: flex;
        padding: 32px 24px 64px 24px;
        flex-direction: column;
        align-items: center;

        .priceContainer {
          gap: 0;
        }

        .info {
          justify-content: flex-start;
          align-content: center;
          align-items: center;
          gap: 16px;
          text-align: start;
          flex-flow: row nowrap;

          align-self: stretch;
        }
      }

      .middleCard {
        margin: 0;
        background-color: #5249d9;

        .pricingName {
          margin-top: 0;
        }

        .pricingValue {
          color: var(--white-color);

          .frequency {
            color: var(--white-color);
          }
        }
      }

      .buttonContainer {
        font-size: 24px;
      }
    }
  }

  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
  }

  .annualContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;

    .annualToggleContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: space-around;

      .annualToggleText {
        margin: 0 20px;
      }
    }

    .annual {
      text-align: center;
      margin: 10px 0;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.setting_pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0;

  .title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
  }

  .annualContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .annualToggleContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: space-around;

      .annualToggleText {
        margin: 0 20px;
      }
    }

    .annual {
      text-align: center;
      margin: 10px 0;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .card_holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    .actualPlan {
      font-size: 14px;
      font-weight: 700;
      color: black;
      letter-spacing: 0.3px;
      margin-top: 15px;
    }

    .textHide {
      font-size: 14px;
      font-weight: 700;
      color: transparent;
      margin-top: 15px;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      height: 100%;
      border-radius: 15px;
      padding: 20px;
      border: 1px solid #e0e0e0;

      .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        margin-top: 30px;

        h3 {
          font-size: 35px;
          margin: 0;
          font-weight: 600;
        }

        h6 {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          color: #828282;
          text-transform: uppercase;
        }

        .signUpBtn {
          height: 55px;
          border: none;
          border-radius: 10px;
          margin-top: 15px;
          color: var(--white-color);
          font-weight: 700;
          text-transform: uppercase;
          font-family: inherit;
          cursor: pointer;
        }

        .selectBtn {
          height: 55px;
          border-radius: 10px;
          text-transform: uppercase;
          font-family: inherit;
          cursor: pointer;
        }
      }

      .options {
        text-align: center;

        .price_name {
          margin-bottom: 8px;
          font-size: 33px;
        }

        .info {
          display: flex;
          justify-content: flex-start;
          align-content: center;
          align-items: center;
          gap: 8px;
          text-align: start;
          flex-flow: row nowrap;

          .infoCheck {
            flex-shrink: 0;
            min-width: 20px;
          }

          .infoText {
            width: auto;
          }

          .gray {
            color: gray;
          }

          p {
            font-size: 14px;
            width: 90%;
            line-height: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .card {
      max-width: 380px;
      min-height: 740px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    padding: 20px;

    title {
      margin: 25px 0;
    }

    .card {
      min-height: 0;
      gap: 20px;
    }
  }
}

// Mobile first styling

.loaderWrap {
  min-height: 3.5rem;
}

.pricingAnnually {
  color: var(--card-header-color);
  height: 10px;
  text-align: center;
}

.pricingValue {
  color: var(--card-header-color);
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  min-height: 3.5rem;

  @media all and (min-width: $breakpoint-lg) {
    font-size: 2.5rem;
  }
}

.middleCard .pricingValue,
.middleCard .pricingAnnually,
.middleCard .pricingName {
  --card-header-color: var(--white-color);
}

.pricingName {
  color: var(--card-header-color);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  margin: 0 0 0.5rem;
}

.frequency {
  font-size: 1.5rem;
  font-weight: 500;
}

.buttonContainer {
  padding: 1rem 0 0;

  @media all and (max-width: $breakpoint-md) {
    padding: 0;
  }
}

.infoList {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }
  }
}

.infoText {
  color: var(--info-text-color, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}
